import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderTitle from "@/components/HeaderTitleFaleConosco";
import EstamosInteressados from "@/components/EstamosInteressadosContent";
import FormulariosContatos from "@/components/FormulariosContatosContent";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from '@/components/header-one';
import Unidades from '@/components/Unidades';
import SEO from '../components/seo'

const FaleConoscoPage = () => {
  return (
    <MenuContextProvider> 
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Contato - Telefone - Escola em Brasília | Kingdom School"
            description="Entre em contato para agendar uma visita ou tirar suas dúvidas sobre a melhor escola de Brasília."
            keywords={
              ['contato kingdom school',
                'escola em brasília',
                'agendar visita',
              ]
            }
          />
          <HeaderOne />
          <HeaderTitle />
          <EstamosInteressados />
          <FormulariosContatos />
          <Unidades />
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default FaleConoscoPage;
