/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { formulariosContatos } from "../assets/styles/AboutTwo.styles";
import { formSectionStyles, input, reverse, form } from "../assets/styles/FormSection.styles";

const AboutTwo = () => {
  return (
    <section css={formulariosContatos}>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="box-content-1">
              <h2>Dê uma escola de presente para o seu filho</h2>
              <p>Venha nos visitar, caminhar pela estrutura e descobrir se esse sonho está alinhado com os sonhos que você busca para seus filhos. Queremos conversar com você e apresentar mais detalhes das nossas unidades. Agende sua visita através da Central de Relacionamento ou preencha o formulário abaixo.</p>
              <div>
                <form
                  name='agendar-visita'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                   css={form}
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />
                 <div css={input}>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Digite seu nome"
                      required
                    />
                  </div>
                  <div css={input}>
                    <input
                      type="mail"
                      name="email"
                      placeholder="Digite seu e-mail"
                      required
                    />
                  </div>
                 <div className="btn-submit">
                  <button type='submit'>
                      Enviar
                    </button>
                 </div>
                </form>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="box-content-2">
              <h3>Trabalhe conosco</h3>
              <h2>Faça parte do time Kingdom!</h2>
              <p>Nós buscamos profissionais que se identifiquem com nossa visão e estejam dispostos a viver os desafios propostos em equipe. Caso você tenha se identificado conosco, envie seu currículo para que nossa equipe de Gestão de Pessoas te conheça!</p>
              <div>
                <form
                  name='trabalhe-conosco'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                   css={form}
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='trabalhe-conosco'
                  />
                 <div css={input}>
                    <input
                      type="text"
                      name="nome"
                      placeholder="Digite seu nome"
                      required
                    />
                  </div>
                  <div css={input}>
                    <input
                      type="mail"
                      name="email"
                      placeholder="Digite seu e-mail"
                      required
                    />
                  </div>
                  <div css={input}>
                    <input
                      type="text"
                      name="telefone"
                      placeholder="Contato"
                      required
                    />
                  </div>
                 <div className="btn-submit">
                  <button type='submit'>
                      Enviar
                    </button>
                 </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
