/** @jsx jsx */
import { css } from "@emotion/react";

export const formSectionStyles = css`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  background-color: #ff7843;
  padding-top: 40px;
  h1 {
    color: #2f382b;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    line-height: 100%;
    padding-top: 48px;
    padding-bottom: 8px;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 36px;
      text-align: left;
    }
  }
  p {
    font-family: "montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #2f382b;
    max-width: 580px;
  }
  img {
    @media (max-width: 500px) {
      width: 375px;
    }
  }
  .form-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 35px;
  }
  .form-div {
    /* background-color: #375f92; */
    flex-direction: column;
    display: flex;
    justify-content: center;
    height: 556px;
    border-radius: 16px;
    @media (max-width: 768px) {
      margin-bottom: 32px;
    }

    h1{
      color: #2F382B;
      line-height: 120%;
    }
    p{
      margin-bottom:24px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 380px;
    height: 556px;
    gap: 8px;
    /* align-items: center; */
    border-radius: 16px;

    button {
      align-self: flex-start;
      align-items: center;
      border-radius: 4px;
      background-color: #ffffff;
      border: none;
      color: #2f382b;
      display: flex;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      justify-content: center;
      line-height: 150%;
      margin-top: 32px;
      padding: 12px 32px;
      width: fit-content;
      text-transform: uppercase;
      transition: .3s;
      @media (max-width: 768px) {
        align-self: flex-start;
      }
      &:hover{
        background-color: #2f382b;
      color: #ffffff;
      }
    }
    input {
      border: none;
    background-color: #ffffff;
    width: 100%;
    height: 35px;
    position: relative;
    padding: 0 12px;
    font-size: 16px;
    z-index: 1;
    border-radius: 4px;
    color: #2f382b;
    margin-bottom: 8px;

      &::placeholder {
        font-size: 16px;
        color: #2f382b;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        margin-top: 0;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      width: 100%;

      height: 54px;
      position: relative;

      &::placeholder {
        font-size: 18px;
        color: #375f92;
        font-weight: 700;
        line-height: 120%;
      }

      &::placeholder {
        position: absolute;
        top: 16px;
        left: 0;
      }
    }
    #mensagem {
      height: 92px;
    }

    select{
      border:none;
      border-radius: 4px;
      font-size: 16px;
      padding: 8px;
      font-weight: 700;
      color: #2f382b;
    }
  }

  .col-img {
    display: flex;
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
    }

    img {
      z-index: 0;
      &:nth-of-type(2) {
        position: absolute;
        top: 40px;
        z-index: 0;
      }
    }
  }
`;

export const input = css`
  position: relative;
  /* &::after {
    width: 100%;
    height: 10px;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    border-radius: 2px;
    bottom: 6px;
    z-index: 0;
  } */
`;

export const reverse = css`
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const form = css`
 .select__control{
  border:none;
  /* &::after {
    width: 100%;
    height: 10px;
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    border-radius: 2px;
    bottom: 13px;
    z-index: 0;
  } */
  &:focus{
    border:none;
    outline: none;
  }
 }

 .select__control--is-focused{
  border: none;
  outline: none;
  box-shadow: none;
 }

 .select__single-value {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: hsl(0, 0%, 20%);
    margin-left: 12px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    z-index: 2;
}

.select__input-container{
  padding: 0;
}

.select__value-container{
  padding: 0px;
}

.select__indicator{
color: #000;
z-index: 2;
}

.select__indicator-separator{
  display: none;
}
.select__menu{
  z-index: 999;
  font-size: 18px;
}
`;
