/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import {
  estamosInteressados,
  content,
  iconsBg5,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

const MetodologiaPautadaContent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={estamosInteressados}>
      <Container fluid>
        <Row className="title-content">
          <Col css={content} lg={7} md={12} sm={12} className="px-0">
            <div className="pb-71 content-box">
              <h2>Estamos interessados no que você tem a dizer!</h2>
              <p>Quer saber mais sobre a Kingdom? Tem dúvidas ou quer enviar sugestões? Entre em contato com a gente via central de relacionamento ou no formulário abaixo.</p>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={5} md={12} sm={12}>
            <div css={iconsBg5} className="bg-image" />
          </Col>
        </Row>
      </Container>
      <Col css={mobile} className="img-container px-0" lg={5} md={12} sm={12}>
        <div css={iconsBg5} className="bg-image" />
      </Col>
    </section>
  );
};

export default MetodologiaPautadaContent;
