/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { ContainerStyled } from '../assets/styles/Unidades.styles'
import { Col, Container, Row } from 'react-bootstrap'

const Unidades = () => {
  const [selectedUnit, setSelectedUnit] = useState('infantil')

  return (
    <div css={ContainerStyled}>
      <Container>
        <Row className=''>
          <Col
            data-aos="fade-right"
            lg={6}
            md={6}
            sm={12}
          >
            <h1>Onde estamos?</h1>

            <div className='unidade_container'>
              <div className='radio_container'>
                <input
                  type="radio"
                  id="infantil"
                  name="unidade"
                  value="infantil"
                  checked={selectedUnit === 'infantil'}
                  onChange={() => setSelectedUnit('infantil')}
                />
                <label htmlFor="infantil">
                  <h4>Unidade Educação Infantil</h4>
                </label>
              </div>
              <p>
                Setor de Habitações Individuais Sul EQL 6/8 Lago Sul, Brasília DF, CEP: 70040-906
              </p>
            </div>

            <div className='unidade_container'>
              <div className='radio_container'>
                <input
                  type="radio"
                  id="fundamental"
                  name="unidade"
                  value="fundamental"
                  checked={selectedUnit === 'fundamental'}
                  onChange={() => setSelectedUnit('fundamental')}
                />
                <label htmlFor="fundamental">
                  <h4>Unidade Ensino Fundamental</h4>
                </label>
              </div>
              <p>
                Setor de Habitações Individuais Sul QI 11 área especial B, Entre as quadras 02 e 04 - Brasília/DF - CEP: 71625-600
              </p>
            </div>

          </Col>

          <Col lg={6} md={6} sm={12}>
            {selectedUnit === 'infantil' ? (
              <div>
                <iframe
                  style={{ border: 'none', width: '100%', height: 400 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1613.7847934976135!2d-47.896586684636006!3d-15.843519967933934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a2516cf9066bd%3A0x10c22b0f09886098!2sKingdom%20Kids!5e0!3m2!1spt-BR!2sbr!4v1726921419875!5m2!1spt-BR!2sbr"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            ) : (
              <div>
                <iframe
                  style={{ border: 'none', width: '100%', height: 400 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6455.481188732809!2d-47.872899539802624!3d-15.832819455787034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a25aed15f1cc9%3A0x3c4d084bdcbeef6!2sKingdom%20School!5e0!3m2!1spt-BR!2sbr!4v1726921665091!5m2!1spt-BR!2sbr"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Unidades
