/** @jsx jsx */
import { css } from "@emotion/react";

export const ContainerStyled = css`
height: 100%;
max-height: 647px;
background-color: #5315FF;
padding-block: 114px;
@media (max-width: 768px) {
  max-height: fit-content;
}

.radio_container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
}
.unidade_container:first-of-type {  
  margin-bottom: 56px;
}

h1 {
  color: #FFF;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.8px;
  margin-bottom: 84px;
}
h4 {
  color:  #FFF;
font-family: Montserrat;
font-size: 16px;
font-weight: 500;
line-height: 150%;
margin: 0;
cursor: pointer;
}
label {
  margin: 0;
}
p {
  color: #FFF;
font-size: 16px;
font-weight: 500;
line-height: 150%; 
}
input {
  accent-color: #FFA300;
}
`